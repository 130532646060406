
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { roundToFixed } from '@/util/number';
import gdbx from '@/store/modules/gdbx';
import ShowProfitToggle from '@/components/ShowProfitToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
	components: {
		AmountDisplayerFilterable,
		ShowProfitToggle,
	},
})
export default class CurrentMonthCustomerSalesProfitNumberDisplayer extends FilterWidget {
	@Prop({ default: '' }) public defaultCustomer!: string;

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public profitPercentage = 0;
	public profitAmount = '' || 0;

	public get permissionIds(): PermissionsGroup[] {
		return ['customers', 'sales'];
	}

	public get showProfit() {
		return settingx.now.showProfit;
	}

	public get title() {
		return this.showProfit ? 'MTD Gross Profit' : 'MTD Gross Margin';
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			defaultCustomer,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			defaultCustomer,
		]);
	}

	public async expensiveCalc() {
		const salesRef = FilteredDatabase.ref('sales')
			.customers([this.defaultCustomer])
			.dateRange(this.selectedDateRange);

		const creditNoteRef = FilteredDatabase.ref('creditNotes')
			.customers([this.defaultCustomer])
			.dateRange(this.selectedDateRange);

		const salesAmount = await (await salesRef.get()).getSum('amount');
		const profitAmount = await (await salesRef.get()).getSum('profit');
		const CNAmount = await (await creditNoteRef.get()).getSum('amount');

		this.profitAmount = roundToFixed(profitAmount - CNAmount, this.decimal);

		this.profitPercentage = roundToFixed(
			salesAmount !== 0 ? (this.profitAmount / salesAmount) * 100 : 0,
			this.decimal,
		);

		this.saveHistory('profitAmount', 'profitPercentage');
	}
}
