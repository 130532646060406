
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup, SalesDetailCard } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class CurrentMonthSalesNumberDisplayer extends FilterWidget {
	@Prop({ default: '' }) public defaultCustomer!: string;

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public individualRanking = 0;
	public allRanking = 0;
	public salesAmount = '' || 0;

	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			defaultCustomer,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			defaultCustomer,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('customerTrades')
			.customers([this.defaultCustomer])
			.dateRange(this.selectedDateRange)
			.includes('docType', ['IV', 'DN']);

		const tradeCNRef = FilteredDatabase.ref('customerTrades')
			.customers([this.defaultCustomer])
			.dateRange(this.selectedDateRange)
			.includes('docType', ['CN']);

		const allTradeRef = FilteredDatabase.ref('customerTrades')
			.customers(this.selectedCustomers)
			.dateRange(this.selectedDateRange)
			.includes('docType', ['IV', 'DN']);

		const allRefHead = await allTradeRef.get();

		const allCards: SalesDetailCard[] = await allRefHead.getCards();

		const allTimeResult = {};
		for (const item of allCards) {
			if (!allTimeResult[item.code]) {
				allTimeResult[item.code] = [];
			}
			allTimeResult[item.code].push(item.amount);
		}

		const allTimeResult2 = {};

		for (const code in allTimeResult) {
			if (allTimeResult.hasOwnProperty(code)) {
				const salesAmountList = allTimeResult[code] as number[];
				const sortedSalesAmount = salesAmountList.reduce((a, b) => a + b, 0);
				if (!allTimeResult2[code]) {
					allTimeResult2[code] = [];
				}
				allTimeResult2[code].push(sortedSalesAmount);
			}
		}

		const sortedAllTimeList: Array<{ code: string; amount: number }> = [];

		for (const code in allTimeResult2) {
			if (allTimeResult2.hasOwnProperty(code)) {
				const object = { code: '', amount: 0 };
				object.code = code;
				object.amount = allTimeResult2[code][0];
				sortedAllTimeList.push(object);
			}
		}

		sortedAllTimeList.sort((a, b) => b.amount - a.amount);

		this.individualRanking =
			sortedAllTimeList.findIndex(
				(item) => item.code === this.defaultCustomer,
			) + 1;

		this.allRanking = sortedAllTimeList.length;

		const salesAmount = await (await tradeRef.get()).getSum('amount');
		const CNAmount = await (await tradeCNRef.get()).getSum('amount');

		this.salesAmount = salesAmount;

		this.saveHistory('salesAmount', 'individualRanking', 'allRanking');
	}
}
