
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import moment from 'moment';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup, ValueText } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import { DataViewFormatter } from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import Button from 'primevue/button';

@Component({
	components: {
		SecondaryLineBarChartFilterable,
		SelectFilter,
		DataInfo,
		Button,
	},
})
export default class CustomerSalesTrendBarLineChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public xAxisData: string[] = [];
	public series: echarts.ECharts[] | echarts.ECharts | any = [];

	public selectedColumn: string = this.columnList[0].value;
	public selectedLine: string = this.lineList[0].value;
	public selectedComparedYear: any = this.currentYear - 1;
	public isConfirmApply: number = 0;

	public columnDatas: number[] = [];
	public comparedColumnDatas: number[] = [];
	public accumulatedLineResults: number[] = [];
	public accumulatedComparedLineResults: number[] = [];

	public applyFilter() {
		this.isConfirmApply += 1;
	}

	public get yAxisLabel2() {
		let label: string = '';

		if (this.selectedLine === 'salesMargin') {
			label = '%';
		}

		if (
			this.selectedLine === 'cummulativeSales' ||
			this.selectedLine === 'cummulativeProfit' ||
			this.selectedLine === 'outstanding' ||
      this.selectedLine === 'payment'
		) {
			label = this.currencySymbol;
		}
		return label;
	}

	public get compareYearList() {
		const currentYear = moment().year();

		const list: any [] = [];

		for (let i = 1; i < 30; i++) {
			const year = currentYear - i;
			list.push(year);
		}

  list.unshift('None');

		return list;
	}

	public get selectedLineName() {
		return this.lineList.find((item) => item.value === this.selectedLine)!.text;
	}

	public get columnList(): ValueText[] {
		const list = [
			{ text: 'Sales', value: 'sales' },
			{ text: 'Sales Profit', value: 'salesProfit' },
			{ text: 'Payment', value: 'payment' },
			{ text: 'Credit Note', value: 'creditNote' },
		];

		return list;
	}

	public get lineList(): ValueText[] {
		const list = [
			{ text: 'None', value: 'none' },
			{ text: 'Outstanding', value: 'outstanding' },
			{ text: 'Sales Margin (Percentage)', value: 'salesMargin' },
        // remove cumulative
			{ text: 'Sales', value: 'cummulativeSales' },
        // remove cumulative
			{ text: 'Profit', value: 'cummulativeProfit' },
        // remove cumulative
			{ text: 'Credit Note', value: 'cummulativeCreditNote' },
      { text: 'Payment', value: 'payment' },
		];

		return list;
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currentMonth() {
		return moment(this.selectedAsOfDate).month();
	}

	public get compareYearRange(): [number, number] {
		let diff = 0;
		if (this.selectedComparedYear) {
			diff = moment(this.selectedAsOfDate).year() - this.selectedComparedYear;
		}
		return [
			moment(this.selectedAsOfDate)
				.add(-diff, 'year')
				.startOf('year')
				.valueOf(),
			moment(this.selectedAsOfDate).add(-diff, 'year').endOf('year').valueOf(),
		];
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get dataViewFormatter() {
		return DataViewFormatter.salesTracker;
	}

	public get expensiveHook() {
		const {
			isConfirmApply,
			currentYear,
			selectedCustomers,
			selectedAgents,
			currentMonth,
		} = this;
		return JSON.stringify([
			isConfirmApply,
			currentYear,
			selectedCustomers,
			selectedAgents,
			currentMonth,
		]);
	}

	public async expensiveCalc() {
		const currentMonthIndex = this.currentMonth + 1;

		// // As Of Selected Date

		// const asOfIvdnRef = FilteredDatabase.ref('customerIvDns')
		// 	.dateAsOf(this.selectedAsOfDate)
		// 	.customers(this.selectedCustomers);

		// const asOfKoRef = FilteredDatabase.ref('customerKos')
		// 	.customers(this.selectedCustomers)
		// 	.numberRange('koDate', [
		// 		['>', Number.NEGATIVE_INFINITY],
		// 		['<=', this.selectedAsOfDate],
		// 	]);

		// const asOfUnappliedAmountRef = FilteredDatabase.ref('customerTrades')
		// 	.customers(this.selectedCustomers)
		// 	.dateAsOf(this.selectedAsOfDate);

		// // As Of Compared Date

		// const asOfComparedYearIvdnRef = FilteredDatabase.ref('customerIvDns')
		// 	.customers(this.selectedCustomers)
		// 	.numberRange('date', [
		// 		['>', Number.NEGATIVE_INFINITY],
		// 		['<=', this.compareYearRange[1]],
		// 	]);

		// const asOfComparedYearKoRef = FilteredDatabase.ref('customerKos')
		// 	.customers(this.selectedCustomers)
		// 	.numberRange('koDate', [
		// 		['>', Number.NEGATIVE_INFINITY],
		// 		['<=', this.compareYearRange[1]],
		// 	]);

		// const asOfComparedYearUnappliedAmountRef = FilteredDatabase.ref(
		// 	'customerTrades',
		// )
		// 	.customers(this.selectedCustomers)
		// 	.numberRange('date', [
		// 		['>', Number.NEGATIVE_INFINITY],
		// 		['<=', this.compareYearRange[1]],
		// 	]);

		// Selected date

		const salesRef = FilteredDatabase.ref('sales')
			.dateRange(this.selectedDateRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const CNRef = FilteredDatabase.ref('creditNotes')
			.dateRange(this.selectedDateRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.dateRange(this.selectedDateRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const tradeRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const tradeIvDnRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.includes('docType', ['IV', 'DN']);

		const tradeCNRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.includes('docType', ['CN']);

		const paymentRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.includes('docType', ['PM']);

		const koRef = FilteredDatabase.ref('customerKos')
			.customers(this.selectedCustomers)
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.numberRange('koDate', [
				['>=', this.selectedDateRange[0]],
				['<=', this.selectedDateRange[1]],
			]);

		// Compared Year

		const salesComparedYearRef = FilteredDatabase.ref('sales')
			.dateRange(this.compareYearRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const CNComparedYearRef = FilteredDatabase.ref('creditNotes')
			.dateRange(this.compareYearRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const ivdnComparedYearRef = FilteredDatabase.ref('customerIvDns')
			.dateRange(this.compareYearRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const tradeComparedYearRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.compareYearRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const tradeIvDnComparedYearRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.compareYearRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.includes('docType', ['IV', 'DN']);

		const tradeCNComparedYearRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.compareYearRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.includes('docType', ['CN']);

		const paymentComparedYearRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.compareYearRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.includes('docType', ['PM']);

		const koComparedYearRef = FilteredDatabase.ref('customerKos')
			.dateRange(this.compareYearRange)
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.numberRange('koDate', [
				['>=', this.compareYearRange[0]],
				['<=', this.compareYearRange[1]],
			]);

		let pa = 0.04;
		let pb = 0;

		const profitDD = await this._loadDimensionByPeriod(
			salesRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.04;

		const profitCNDD = await this._loadDimensionByPeriod(
			CNRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.04;

		const salesDD = await this._loadDimensionByPeriod(
			salesRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const salesCNDD = await this._loadDimensionByPeriod(
			CNRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const ivdnDD = await this._loadDimensionByPeriod(
			ivdnRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const paymentDD = await this._loadDimensionByPeriod(
			paymentRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const tradeDD = await this._loadDimensionByPeriod(
			tradeIvDnRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const tradeCNDD = await this._loadDimensionByPeriod(
			tradeCNRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const koDD = await this._loadDimensionByPeriod(
			koRef,
			'month',
			pa,
			pb,
			'amount',
			'koDate',
		);

		pb += pa;
		pa = 0.04;

		const gainLossDD = await this._loadDimensionByPeriod(
			koRef,
			'month',
			pa,
			pb,
			'gainLoss',
		);

		pb += pa;
		pa = 0.04;

		const unappliedAmountDD = await this._loadDimensionByPeriod(
			tradeRef,
			'month',
			pa,
			pb,
			'unappliedAmount',
		);

		// Compared Year DD

		pb += pa;
		pa = 0.04;

		const salesComparedYearDD = await this._loadDimensionByPeriod(
			salesComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const CNComparedYearDD = await this._loadDimensionByPeriod(
			CNComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const profitComparedYearDD = await this._loadDimensionByPeriod(
			salesComparedYearRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.04;

		const profitCNComparedYearDD = await this._loadDimensionByPeriod(
			CNComparedYearRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.04;

		const ivdnComparedYearDD = await this._loadDimensionByPeriod(
			ivdnComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const paymentComparedYearDD = await this._loadDimensionByPeriod(
			paymentComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const tradeComparedYearDD = await this._loadDimensionByPeriod(
			tradeIvDnComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const tradeCNComparedYearDD = await this._loadDimensionByPeriod(
			tradeCNComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.04;

		const koComparedYearDD = await this._loadDimensionByPeriod(
			koComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
			'koDate',
		);

		pb += pa;
		pa = 0.04;

		const gainLossComparedYearDD = await this._loadDimensionByPeriod(
			koComparedYearRef,
			'month',
			pa,
			pb,
			'gainLoss',
		);

		pb += pa;
		pa = 0.04;

		const unappliedAmountComparedYearDD = await this._loadDimensionByPeriod(
			tradeComparedYearRef,
			'month',
			pa,
			pb,
			'unappliedAmount',
		);

		this.generateLoadingText(1);

		let lineDatas: number[] = [];
		let comparedLineDatas: number[] = [];

		const accumulatedProfitDatas: number[] = [];
		const accumulatedSalesDatas: number[] = [];
		const accumulatedComparedProfitDatas: number[] = [];
		const accumulatedComparedSalesDatas: number[] = [];

		this.accumulatedLineResults = [];
		this.accumulatedComparedLineResults = [];

		switch (this.selectedColumn) {
			case 'sales':
				this.columnDatas = tradeDD.map(
					(dd, index) => dd.sum - tradeCNDD[index].sum,
				);
				this.comparedColumnDatas = tradeComparedYearDD.map(
					(dd, index) => dd.sum - tradeCNComparedYearDD[index].sum,
				);
				break;
			case 'salesProfit':
				this.columnDatas = profitDD.map(
					(dd, index) => dd.sum - profitCNDD[index].sum,
				);
				this.comparedColumnDatas = profitComparedYearDD.map(
					(dd, index) => dd.sum - profitCNComparedYearDD[index].sum,
				);
				break;
			case 'payment':
				this.columnDatas = paymentDD.map((dd) => dd.sum);
				this.comparedColumnDatas = paymentComparedYearDD.map((dd) => dd.sum);
				break;
			case 'creditNote':
				this.columnDatas = tradeCNDD.map((dd) => dd.sum);
				this.comparedColumnDatas = tradeCNComparedYearDD.map((dd) => dd.sum);
				break;
		}

		switch (this.selectedLine) {
			case 'outstanding':
				// const accumulatedIvDnAmount =
				// 	(await (await asOfIvdnRef.get()).getSum('amount')) -
				// 	(await (await asOfKoRef.get()).getSum('amount')) -
				// 	(await (await asOfKoRef.get()).getSum('gainLoss')) -
				// 	(await (await asOfUnappliedAmountRef.get()).getSum(
				// 		'unappliedAmount',
				// 	));
				// const accumulatedComparedIvDnAmount =
				// 	(await (await asOfComparedYearIvdnRef.get()).getSum('amount')) -
				// 	(await (await asOfComparedYearKoRef.get()).getSum('amount')) -
				// 	(await (await asOfComparedYearKoRef.get()).getSum('gainLoss')) -
				// 	(await (await asOfComparedYearUnappliedAmountRef.get()).getSum(
				// 		'unappliedAmount',
				// 	));

				lineDatas = ivdnDD.map(
					(dd, index) =>
						dd.sum -
						koDD[index].sum -
						gainLossDD[index].sum -
						unappliedAmountDD[index].sum,
				);
				comparedLineDatas = ivdnComparedYearDD.map(
					(dd, index) =>
						dd.sum -
						koComparedYearDD[index].sum -
						gainLossComparedYearDD[index].sum -
						unappliedAmountComparedYearDD[index].sum,
				);
				break;
			case 'salesMargin':
				for (let i = 0; i < currentMonthIndex; i++) {
					if (i === 0) {
						accumulatedProfitDatas[i] = profitDD[i].sum - profitCNDD[i].sum;
						accumulatedSalesDatas[i] = salesDD[i].sum - profitCNDD[i].sum;
					} else {
						accumulatedProfitDatas[i] =
							profitDD[i].sum -
							profitCNDD[i].sum +
							accumulatedProfitDatas[i - 1];
						accumulatedSalesDatas[i] =
							salesDD[i].sum - salesCNDD[i].sum + accumulatedSalesDatas[i - 1];
					}
				}
				for (let i = 0; i < 12; i++) {
					if (i === 0) {
						accumulatedComparedProfitDatas[i] =
							profitComparedYearDD[i].sum - profitCNComparedYearDD[i].sum;
						accumulatedComparedSalesDatas[i] =
							salesComparedYearDD[i].sum - CNComparedYearDD[i].sum;
					} else {
						accumulatedComparedProfitDatas[i] =
							profitComparedYearDD[i].sum -
							profitCNComparedYearDD[i].sum +
							accumulatedComparedProfitDatas[i - 1];
						accumulatedComparedSalesDatas[i] =
							salesComparedYearDD[i].sum -
							CNComparedYearDD[i].sum +
							accumulatedComparedSalesDatas[i - 1];
					}
				}
				break;
			case 'cummulativeSales':
				lineDatas = tradeDD.map((dd, index) => dd.sum - tradeCNDD[index].sum);
				comparedLineDatas = tradeComparedYearDD.map(
					(dd, index) => dd.sum - tradeCNComparedYearDD[index].sum,
				);
				break;
			case 'cummulativeCreditNote':
				lineDatas = tradeCNDD.map((dd) => dd.sum);
				comparedLineDatas = tradeCNComparedYearDD.map((dd) => dd.sum);
				break;
			case 'cummulativeProfit':
				lineDatas = profitDD.map((dd, index) => dd.sum - profitCNDD[index].sum);
				comparedLineDatas = profitComparedYearDD.map(
					(dd, index) => dd.sum - profitCNComparedYearDD[index].sum,
				);
				break;
      case 'payment':
        lineDatas = paymentDD.map((dd) => dd.sum);
        comparedLineDatas = paymentComparedYearDD.map((dd) => dd.sum);
        break;
			case 'none':
				break;
		}

		for (let i = 0; i < 12; i++) {
			if (i === 0) {
				this.accumulatedComparedLineResults[i] = comparedLineDatas[i];
				this.accumulatedLineResults[i] = lineDatas[i];
			} else {
				this.accumulatedComparedLineResults[i] =
					comparedLineDatas[i] + this.accumulatedComparedLineResults[i - 1];
				this.accumulatedLineResults[i] =
					lineDatas[i] + this.accumulatedLineResults[i - 1];
			}
			if (this.selectedLine === 'salesMargin') {
				this.accumulatedLineResults[i] =
					(accumulatedProfitDatas[i] / accumulatedSalesDatas[i]) * 100;
				this.accumulatedComparedLineResults[i] =
					(accumulatedComparedProfitDatas[i] /
						accumulatedComparedSalesDatas[i]) *
					100;
			}
		}

		const yearDatas: echarts.ECharts | any = {
			name:
				this.selectedColumn[0].toUpperCase() +
				this.selectedColumn.substring(1) +
				' ' +
				this.currentYear,
			type: 'bar',
			barWidth: '30%',
			itemStyle: { color: GobiColor.COLOR1 },
			data: this.columnDatas,
		};

		const comparedYearDatas: echarts.ECharts | any = {
			name:
				this.selectedColumn[0].toUpperCase() +
				this.selectedColumn.substring(1) +
				' ' +
				this.selectedComparedYear,
			type: 'bar',
			itemStyle: { color: GobiColor.COLOR3 },
			barWidth: '30%',
			data: this.comparedColumnDatas,
		};

		// const invisibleDatas: echarts.ECharts | any = {
		// 	name: '',
		// 	type: 'bar',
		// 	itemStyle: { opacity: 0 },
		// 	barWidth: '1%',
		// 	data: { value: this.invisibleMax, label: { show: false } },
		// };

		const line: echarts.ECharts | any = {
			name: this.selectedLineName + ' ' + this.currentYear,
			type: 'line',
			itemStyle: { color: GobiColor.COLOR5 },
			yAxisIndex: 1,
			data: this.accumulatedLineResults,
		};

		const comparedLine: echarts.ECharts | any = {
			name: this.selectedLineName + ' ' + this.selectedComparedYear,
			type: 'line',
			itemStyle: { color: GobiColor.COLOR6 },
			yAxisIndex: 1,
			data: this.accumulatedComparedLineResults,
		};

		this.xAxisData = ivdnComparedYearDD
			.map((dd) => dd.text)
			.map((month) => month.split(' '))
			.map((splitMonth) => splitMonth[0]);

		this.series = [];

  if (this.selectedComparedYear === 'None') {
      this.series = [yearDatas, line];
    } else {
      this.series = [yearDatas, comparedYearDatas, line];
    }
		if (this.selectedComparedYear !== null) {
		}
    // else {
		// 	this.series = [yearDatas, line];
		// }

		this.saveHistory('series', 'xAxisData');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
