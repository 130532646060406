
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Page from './Page.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import AllTimeTradeNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/AllTimeTradeNumberDisplayer.vue';
import YTDCustomerSalesNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/YTDCustomerSalesNumberDisplayer.vue';
import YTDCustomerSalesProfitNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/YTDCustomerSalesProfitNumberDisplayer.vue';
import CurrentYearTradeNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/CurrentYearTradeNumberDisplayer.vue';
import CurrentMonthCustomerSalesNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/CurrentMonthCustomerSalesNumberDisplayer.vue';
import CurrentMonthCustomerSalesProfitNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/CurrentMonthCustomerSalesProfitNumberDisplayer.vue';
import CustomerSalesTrendBarLineChart from '@/components/reports-v2/components/codedWidgets/sales/CustomerSalesTrendBarLineChart.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import gdbx from '@/store/modules/gdbx';
import CustomerRadioFilter from '@/components/reports-v2/components/filters/CustomerRadioFilter.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { ValueText } from '@/store/models.def';
import Message from 'primevue/message';
import SalesAnalysisTreeMap from '@/components/reports-v2/components/codedWidgets/sales/SalesAnalysisTreeMap.vue';
import Divider from 'primevue/divider';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';

@Component({
	components: {
		CurrentMonthCustomerSalesNumberDisplayer,
		AllTimeTradeNumberDisplayer,
		YTDCustomerSalesNumberDisplayer,
		YTDCustomerSalesProfitNumberDisplayer,
		CurrentYearTradeNumberDisplayer,
		CurrentMonthCustomerSalesProfitNumberDisplayer,
		StickyFilterContainer,
		DateFilter,
		LastSync,
		CustomerRadioFilter,
		CustomerSalesTrendBarLineChart,
		SelectFilter,
		Message,
		SalesAnalysisTreeMap,
		Divider,
	},
})
export default class Page1 extends Page {
	public accountManager: string = '';
	public relationship: string = 'No Record';
  public creditLimit: string = '';
  public customerCards = [];

	public async mounted() {
		this.track();
  const ref = FilteredDatabase.ref('globalCustomers');
  const cards = await (await ref.get()).getCards();
  const limit = cards.filter((a) => {
      return a.code === this.defaultCustomer;
        },
    )[0].creditLimit;

  this.creditLimit = gdbx.currencySymbol + ' ' + limit.toLocaleString();

    // (this.$refs.stickyBottom as any).handleScroll();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public receivedAccountManager(value: string) {
		  this.accountManager = value;
	}

	public receivedRelationship(value: string) {
		this.relationship = value;
	}

	// public get selectedAsOfDate() {
	// 	return moment(this.selectedDay).endOf('month').valueOf();
	// }

	public get selectedCurrentMonthRange(): [number, number] {
		return [
			moment(this.selectedAsOfDate).startOf('month').valueOf(),
			moment(this.selectedAsOfDate).endOf('month').valueOf(),
		];
	}

	// public get selectedDateRange(): [number, number] {
	// 	return [
	// 		moment(this.selectedDay).startOf('year').valueOf(),
	// 		moment(this.selectedDay).endOf('year').valueOf(),
	// 	];
	// }
  //
	public get selectedYearToDateRange(): [number, number] {
		return [
			moment(this.selectedAsOfDate).startOf('year').valueOf(),
			moment(this.selectedAsOfDate).endOf('month').valueOf(),
		];
	}

	public get selectedStockItems() {
		return gdbx.allStockItems.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
