
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import RadioFilter from './RadioFilter.vue';
import gdbx from '@/store/modules/gdbx';
import RadioFilterContainer from './RadioFilterContainer.vue';

@Component({
	components: {
		RadioFilter,
	},
})
export default class CustomerRadioFilter extends RadioFilterContainer {
	public get options() {
		return gdbx.allCustomers;
	}

	public get categories() {
		return gdbx.allCustomerCategories;
	}
}
