
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment, { Moment } from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import ShowProfitToggle from '@/components/ShowProfitToggle.vue';
import settingx from '@/store/modules/settingx';
import { roundToFixed } from '@/util/number';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		AmountDisplayerFilterable,
		ShowProfitToggle,
	},
})
export default class YTDCustomerSalesProfitNumberDisplayer extends FilterWidget {
	@Prop({
		default: () => [
			moment().startOf('year').valueOf(),
			moment().endOf('month').valueOf(),
		],
	})
	public selectedYearToDateRange!: [number, number];

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public profitPercentage = 0;
	public profitAmount = '' || 0;

	public get permissionIds(): PermissionsGroup[] {
		return ['customers', 'sales'];
	}

	public get showProfit() {
		return settingx.now.showProfit;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get title() {
		return this.showProfit ? 'YTD Gross Profit' : 'YTD Gross Margin';
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedYearToDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			selectedCustomers,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedYearToDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		const YTDThisYear = this.selectedYearToDateRange;

		const salesRef = FilteredDatabase.ref('sales')
			.customers(this.selectedCustomers)
			.dateRange(YTDThisYear);

		const thisYearCNRef = FilteredDatabase.ref('creditNotes')
			.customers(this.selectedCustomers)
			.dateRange(YTDThisYear);

		const salesHead = await salesRef.get();
		let salesAmount = await salesHead.getSum('amount');
		const profitAmount = await salesHead.getSum('profit');

		const cnHead = await thisYearCNRef.get();
		const cnAmount = await cnHead.getSum('amount');
		const cnProfit = await cnHead.getSum('profit');

		this.profitAmount = profitAmount - cnProfit;

		salesAmount = salesAmount;

		this.profitPercentage = roundToFixed(
			salesAmount !== 0 ? (this.profitAmount / salesAmount) * 100 : 0,
			this.decimal,
		);

		this.saveHistory('profitPercentage', 'profitAmount');
	}
}
