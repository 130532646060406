
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { roundToFixed } from '@/util/number';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class CurrentYearTradeNumberDisplayer extends FilterWidget {
	@Prop({ default: '' }) public defaultCustomer!: string | null;

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public salesPercentage = '' || 0;
  public amount = '';

	public get permissionIds(): PermissionsGroup[] {
		return ['customers', 'sales'];
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			currentYear,
			defaultCustomer,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			currentYear,
			defaultCustomer,
		]);
	}

	public async expensiveCalc() {
		const totalSalesRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.includes('docType', ['IV', 'DN']);

		const totalCNRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.includes('docType', ['CN']);

		const totalSalesAmount = await (await totalSalesRef.get()).getSum('amount');
		const totalCNAmount = await (await totalCNRef.get()).getSum('amount');

		const salesRef = FilteredDatabase.ref('customerTrades')
			.customers(this.defaultCustomer ? [this.defaultCustomer] : [])
			.dateRange(this.selectedDateRange)
			.includes('docType', ['IV', 'DN']);

		const CNRef = FilteredDatabase.ref('customerTrades')
			.customers(this.defaultCustomer ? [this.defaultCustomer] : [])
			.dateRange(this.selectedDateRange)
			.includes('docType', ['CN']);

		const agentSalesAmount = await (await salesRef.get()).getSum('amount');
		const agentCNAmount = await (await CNRef.get()).getSum('amount');

		this.salesPercentage = roundToFixed(
			(agentSalesAmount / totalSalesAmount) * 100,
			this.decimal,
		);

  this.amount = this.currency
      + ' ' + agentSalesAmount.toLocaleString()
      + ' / ' + this.currency
      + ' ' + totalSalesAmount.toLocaleString();

		this.saveHistory('salesPercentage');
	}
}
