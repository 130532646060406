
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import {
	CustomerSupplierTradeCard,
	PermissionsGroup,
	SalesDetailCard,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class YTDCustomerSalesNumberDisplayer extends FilterWidget {
	@Prop({ default: '' }) public defaultCustomer!: string;

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public salesAmount = 0;
	public individualSalesRank = 0;
	public allSalesRank = 0;

	public get permissionIds(): PermissionsGroup[] {
		return ['customers', 'sales'];
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			defaultCustomer,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			defaultCustomer,
		]);
	}

	public async expensiveCalc() {

		const allTradeIvdnRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.customers(this.selectedCustomers)
			.includes('docType', ['IV', 'DN']);

		const allTradeCNRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.customers(this.selectedCustomers)
			.includes('docType', ['CN']);

		const allTradeRefHead = await allTradeIvdnRef.get();
		const allTradeCNRefHead = await allTradeCNRef.get();

		const cards: CustomerSupplierTradeCard[] = await allTradeRefHead.getCards();

		const allCustomerList: string[] = [
			...(await (await allTradeRefHead).getSet('code')),
		];

		const customerSalesResult = {};
		for (const item of cards) {
			if (!customerSalesResult[item.code]) {
				customerSalesResult[item.code] = [];
			}
			customerSalesResult[item.code].push(item.amount);
		}

		const allTimeResult2 = {};

		for (const code in customerSalesResult) {
			if (customerSalesResult.hasOwnProperty(code)) {
				const salesAmount = customerSalesResult[code] as number[];
				const sortedSalesAmount = salesAmount.reduce((a, b) => a + b, 0);
				if (!allTimeResult2[code]) {
					allTimeResult2[code] = [];
				}
				allTimeResult2[code].push(sortedSalesAmount);
			}
		}

		const sortedAllTimeList: Array<{ code: string; amount: number }> = [];

		for (const code in allTimeResult2) {
			if (allTimeResult2.hasOwnProperty(code)) {
				const object = { code: '', amount: 0 };
				object.code = code;
				object.amount = allTimeResult2[code][0];
				sortedAllTimeList.push(object);
			}
		}

		sortedAllTimeList.sort((a, b) => b.amount - a.amount);

		this.allSalesRank = allCustomerList.length;

		this.individualSalesRank =
			sortedAllTimeList.findIndex(
				(item) => item.code === this.defaultCustomer,
			) + 1;

		this.salesAmount =
			this.individualSalesRank !== 0
				? sortedAllTimeList[this.individualSalesRank - 1].amount
				: 0;

		this.saveHistory('allSalesRank', 'individualSalesRank', 'salesAmount');
	}
}
