
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import {
	CustomerSupplierTradeCard,
	PermissionsGroup,
} from '@/store/models.def';
import { addComma, roundToFixed } from '@/util/number';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class AllTimeTradeNumberDisplayer extends FilterWidget {
	@Prop({ default: '' }) public defaultCustomer!: string;

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public relationship = '';
	public allTimeSales = '' || 0;
	public averageAnnualSales = '' || 0;
	public servingYear = '' || 0;
	public accountManager = '';

	@Watch('accountManager', { immediate: true })
	public onAccountManagerChange(newVal) {
		this.$emit('returnAccountManager', this.accountManager);
	}

	@Watch('relationship', { immediate: true })
	public onRelationshipChange(newVal) {
		this.$emit('returnRelationship', this.relationship);
	}

	public get permissionIds(): PermissionsGroup[] {
		return ['customers', 'sales'];
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get averageAnnualSalesText() {
		return addComma(this.averageAnnualSales, this.decimal);
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			currentYear,
			defaultCustomer,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			currentYear,
			defaultCustomer,
		]);
	}

	public async expensiveCalc() {
		const globalRef = FilteredDatabase.ref('globalCustomers').customers([
			this.defaultCustomer,
		]);

		const globalCards: CustomerSupplierTradeCard[] = await (
			await globalRef.get()
		).getCards();

		this.accountManager =
			globalCards.length > 0 ? gdbx.agentNames[globalCards[0].agent] : '';

		if (globalCards.length > 0) {
			const firstDealMmt = moment(globalCards[0].firstDeal);

			const yearDiff = moment(this.selectedAsOfDate).diff(
				firstDealMmt,
				'years',
				true,
			);

			const roundedYearDiff = roundToFixed(yearDiff, 2);

			if (roundedYearDiff !== 0 && roundedYearDiff <= 1) {
				const monthDiff = Math.trunc(roundedYearDiff * 12);
				this.relationship = monthDiff + ' ' + 'month(s)';
			} else if (roundedYearDiff > 1) {
				const yearOnly = Math.trunc(yearDiff);
				const monthOnly = roundedYearDiff - yearOnly;
				const monthDiff = Math.trunc(monthOnly * 12);

				this.relationship =
					yearOnly + ' ' + 'year(s)' + ' ' + monthDiff + ' ' + 'month(s)';
			} else {
				this.relationship = 'New';
			}
		}

		const tradeRef = FilteredDatabase.ref('customerTrades')
			.customers([this.defaultCustomer])
			.dateAsOf(this.selectedAsOfDate)
			.includes('docType', ['IV', 'DN']);

		const tradeCNRef = FilteredDatabase.ref('customerTrades')
			.customers([this.defaultCustomer])
			.dateAsOf(this.selectedAsOfDate)
			.includes('docType', ['CN']);

		const lastAsOfTradeRef = FilteredDatabase.ref('customerTrades')
			.customers([this.defaultCustomer])
			.dateAsOf(
				moment(this.selectedAsOfDate).add(-1, 'year').endOf('year').valueOf(),
			)
			.includes('docType', ['IV', 'DN']);

		const lastAsOfTradeCNRef = FilteredDatabase.ref('customerTrades')
			.customers([this.defaultCustomer])
			.dateAsOf(
				moment(this.selectedAsOfDate).add(-1, 'year').endOf('year').valueOf(),
			)
			.includes('docType', ['CN']);

		const lastAsOfSalesCards: CustomerSupplierTradeCard[] = await (
			await lastAsOfTradeRef.get()
		).getCards();

		const servingYearsList = lastAsOfSalesCards.map((item) =>
			moment(item.date).year(),
		);

		this.servingYear =
			servingYearsList.length > 0 ? [...new Set(servingYearsList)].length : 1;

		const salesAmount = await (await tradeRef.get()).getSum('amount');
		const CNAmount = await (await tradeCNRef.get()).getSum('amount');

		const lastAsOfSalesAmount = await (
			await lastAsOfTradeRef.get()
		).getSum('amount');
		const lastAsOfCNAmount = await (
			await lastAsOfTradeCNRef.get()
		).getSum('amount');

		this.allTimeSales = salesAmount - CNAmount;
		const lastAsOfSales = lastAsOfSalesAmount - lastAsOfCNAmount;

		this.averageAnnualSales = roundToFixed(
			lastAsOfSales / this.servingYear,
			this.decimal,
		);

		this.saveHistory(
			'customersCount',
			'allTimeSales',
			'averageAnnualSales',
			'servingYear',
			'accountManager',
			'relationship',
		);
	}
}
